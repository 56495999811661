import React from 'react'
import { Link as BaseLink } from 'gatsby'
import styled from 'styled-components'
import { NarrowContainer, Section } from 'src/features/layout'
import { Icon as BaseIcon } from '@foundation-lib/ui'
import { faArrowAltCircleRight } from '@foundation-lib/ui/icons/solid'

const Title = styled.h2`
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 700;
  color: ${p => p.theme.primary};
`

const Body = styled.p`
  color: #6f5c37;
`

export const Link = styled(BaseLink)`
  font-weight: 700;
  text-decoration: none;
  color: ${p => p.theme.primary};
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const Icon = styled(BaseIcon)`
  margin-left: 0.25rem;
`

const Welcome = () => (
  <NarrowContainer>
    <Section>
      <Title>Welcome to Foundations of Grace Publishing!</Title>
      <Body>
        We publish untold stories or rediscover ones that have been forgotten,
        holding them up as a great cloud of witnesses to encourage lives of
        holiness and determination, looking unto the one Author, who is telling
        the grandest story of all.&nbsp;&nbsp;&nbsp;
      </Body>
      <Link to="/about">
        Learn more <Icon icon={faArrowAltCircleRight} />
      </Link>
    </Section>
  </NarrowContainer>
)

export default Welcome
