import React, { useState } from 'react'
import { TextInput, Button } from '@foundation-lib/ui'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { snakeCase, capitalize, trimStart } from 'lodash'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'
import { NarrowContainer } from 'src/features/layout'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

const CurrencyIcon = styled.div.attrs({ children: '$' })`
  color: green;
`

const Description = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  }
`

const InputContainer = styled.div`
  margin: 10px;
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`

const MyTextInput = styled(TextInput)`
  width: 100px;
`

const DonateBar = ({ fund = 'general' }) => {
  const [amountStr, setAmountStr] = useState('')
  const sendCartItemListAction = useCartItemList(s => s.action)
  const handleDonate = () => {
    sendCartItemListAction({
      action: 'add',
      item: {
        label: `Donation - ${capitalize(fund)}`,
        name: `donation-${snakeCase(fund)}`,
        price: parseFloat(amountStr),
        qty: 1,
        hideQty: true,
        addToPrice: true,
      },
    })
    navigate('/cart')
  }

  const handleChange = e => {
    e.preventDefault()
    const strValue = trimStart(e.target.value, '0')
    if (strValue === '') setAmountStr('')
    if (strValue.match(/^[0-9.]*$/)) setAmountStr(strValue)
  }

  return (
    <NarrowContainer>
      <Container>
        <Description>
          <h3 style={{ margin: '0px' }}>
            Donate to help fund our next projects
          </h3>
          <p style={{ margin: '0px' }}>
            We have several books in progress. We need your help to complete
            them!
          </p>
        </Description>
        <InputContainer>
          <MyTextInput
            margin="none"
            leftIcon={<CurrencyIcon />}
            value={amountStr}
            width="small"
            onChange={handleChange}
          />
        </InputContainer>
        <Button disabled={!parseFloat(amountStr)} onClick={handleDonate}>
          Donate
        </Button>
      </Container>
    </NarrowContainer>
  )
}

export default DonateBar
