import React from 'react'
import styled from 'styled-components'
import { Icon as BaseIcon } from '@foundation-lib/ui'
import { faArrowAltCircleRight } from '@foundation-lib/ui/icons/solid'
import { FeaturedBookList, FeaturedArtworkList } from 'src/features/products'
import { Header, Footer } from 'src/features/nav'
import { WideContainer, Section } from 'src/features/layout'
import ProductHero from 'src/features/product-hero'
import { DonateBar } from 'src/features/donation'
import Welcome, { Link as MoreLink } from './Welcome'

const Icon = styled(BaseIcon)`
  margin-left: 0.25rem;
`

const HomePage = () => (
  <>
    <Header />
    <main>
      <ProductHero />
      <Welcome />
      <WideContainer>
        <Section>
          <FeaturedBookList />
        </Section>
        <Section>
          <FeaturedArtworkList />
          <MoreLink to="/products">
            More original artwork <Icon icon={faArrowAltCircleRight} />
          </MoreLink>
        </Section>
      </WideContainer>
      <DonateBar />
    </main>

    <Footer />
  </>
)

export default HomePage
