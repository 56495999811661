import React from 'react'
import SEO from 'src/components/seo'
import HomePage from './HomePage'

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <HomePage />
  </>
)

export default IndexPage
