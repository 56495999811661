import React from 'react'
import styled from 'styled-components'
import { Link as BaseLink } from 'gatsby'
import { Button } from '@foundation-lib/ui'
import mountainGoldBg from '../assets/mountain-gold-bg.jpg'
import itIsLifeToBeOfServiceCoverShort from '../../../../static/images/it-is-life-to-be-of-service-short.jpg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('${mountainGoldBg}');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  @media only screen and (min-width: 600px) {
    background-size: 150% auto;
  }
  @media only screen and (min-width: 800px) {
    background-size: 125% auto;
  }
  @media only screen and (min-width: 1000px) {
    background-size: 100% auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    padding: 2.5rem 1rem;
  }
  @media only screen and (min-width: 800px) {
    padding: 3rem 1.25rem;
  }
  @media only screen and (min-width: 1000px) {
    padding: 3.5rem 1.25rem;
  }
`

const ImageLink = styled(BaseLink)`
  flex-grow: 0;
  flex-shrink: 0;
  outline: 0;
`

const Image = styled.img`
  display: block;
  width: 16rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  @media only screen and (min-width: 450px) {
    width: 18rem;
  }
  @media only screen and (max-width: 599.98px) {
    margin-bottom: 1.5rem;
  }
  @media only screen and (min-width: 600px) {
    width: 16rem;
    padding-right: 1.5rem;
  }
  @media only screen and (min-width: 800px) {
    width: 18rem;
    padding-right: 2rem;
  }
  @media only screen and (min-width: 1000px) {
    width: 20rem;
    padding-right: 2.5rem;
  }
  @media only screen and (min-width: 1200px) {
    width: 22rem;
  }
`

const Details = styled.div`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  @media only screen and (min-width: 600px) {
    width: auto;
  }
`

const Label = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.25rem solid #daad28;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${p => p.theme.primaryReverse};
`

const Link = styled(BaseLink)`
  outline: 0;
  text-decoration: none;
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Bitter', serif;
  font-size: 2rem;
  line-height: 1.25;
  font-weight: 700;
  color: ${p => p.theme.primaryReverse};
  @media only screen and (min-width: 600px) {
    font-size: 2.5rem;
  }
  @media only screen and (min-width: 800px) {
    font-size: 3rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 3.5rem;
  }
  span {
    @media only screen and (max-width: 799.98px) {
      display: none;
    }
  }
`

const ProductHero = () => (
  <Container>
    <Content>
      <ImageLink to="/products/it-is-life-to-be-of-service//audiobook">
        <Image
          src={itIsLifeToBeOfServiceCoverShort}
          alt="It is Life to be of Service cover"
        />
      </ImageLink>
      <Details>
        <Label>It is Life to be of Service</Label>
        <Link to="/products/it-is-life-to-be-of-service//audiobook">
          <Title>
            Hardcover
            <span>
              <br />
            </span>{' '}
            now available!
          </Title>
        </Link>
        <Button
          as={Link}
          to="/products/it-is-life-to-be-of-service//audiobook"
          brand="primary"
          reverse
        >
          Get it today
        </Button>
      </Details>
    </Content>
  </Container>
)

export default ProductHero
